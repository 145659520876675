@import 'reset';
@import 'typography';
@import 'variables';

html {
  font-family: 'Poppins';
}

.m-auto {
  margin: auto;
}

.p-20 {
  padding: 10px;
}

.closeIcon {
  cursor: pointer;
}

.crud {
  padding: 30px;
  border-radius: 20px;
  background-color: $white;
  overflow-x: scroll;
  min-height: 70vh;
  .modal__content {
    min-height: 80vh;
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-part {
      background: $white;
      width: 60%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      padding: 50px;
      label {
        font-family: 'Poppins-SemiBold';
        input,
        select {
          font-family: 'Poppins';
          background: $white-blue;
        }
      }
    }
  }
}

.crud__header {
  display: flex;
  div:first-child {
    margin-right: auto;
  }
}

.overBarGraph {
  background: white;
  padding: 5px 5px;
  box-shadow: 0px 1px 1px 1px #8c8c8c55;
  border-radius: 3px;
  .overLegendColor {
    display: inline-block;
    height: 12px;
    width: 12px;
    margin: 0 5px;
  }
}
