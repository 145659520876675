@import 'assets/styles/variables';

.modal {
  background: $white_blue;
  position: absolute;
  width: 100vw;
  min-height: 100%;
  top: 0;
  left: 0;
  z-index: 201;
  .modal__content h2 {
    color: $black;
    font-size: 34px;
    font-family: 'Poppins-Light';
  }
  .close {
    position: absolute;
    display: inline-block;
    top: 20px;
    right: 20px;
  }
}
