@import 'assets/styles/variables';

button {
  padding: 6px 16px;
  color: #fff;
  border: none;
  line-height: 20px;
  cursor: pointer;
  display: inline-block;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &.primary {
    background-color: $primary;
    color: $white;
    padding: 20px 30px;
    &:hover {
      background-color: darken($primary, 5%);
    }
  }
  &.less_padding {
    padding: 5px 10px;
  }
  &.secondary {
    background-color: $white;
    color: $primary;
    &:hover {
      background-color: darken($white, 5%);
    }
  }
  svg {
    padding-left: 10px;
  }
}
