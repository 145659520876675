@import 'assets/styles/variables';

.QuickViewItem {
  flex: 1;
  background: url('../../../assets/images/wave.svg') no-repeat bottom $primary;
  background-size: cover;
  flex-direction: row;
  min-width: 300px;
  border-radius: 15px;
  & + .QuickViewItem {
    margin-left: 10px;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
  div.content {
    display: flex;
    div {
      padding: 20px 25px;
      display: flex;
      &.data {
        flex: 2;
        flex-direction: column;
        align-items: flex-start;
        span {
          color: $white;
          &.title {
            padding: 3px 0;
            font-size: 18px;
            font-weight: 700;
          }
          &.value {
            font-size: 30px;
            font-family: 'Poppins-Bold';
          }
          &.unit {
            font: 16px;
            font-weight: 500;
            padding-bottom: 10px;
          }
        }
      }
      &.icon {
        flex: 1;
        justify-content: flex-end;
        svg {
          background-color: #ffffff55;
          border-radius: 50%;
          width: 35px;
          height: 35px;
          padding: 10px;
        }
      }
    }
  }
}
