@import 'assets/styles/variables';

.pageds {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: $white_blue;
  justify-content: center;
  align-items: center;

  img {
    width: 300px;
    margin-bottom: 48px;
  }

  .select {
    width: 30%;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .logout {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
