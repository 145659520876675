#FlightTimesStats {
  .graphContainer {
    height: calc(100% - 80px);
  }
  #table {
    flex: 2;
  }
  #chart {
    flex: 5;
  }
}
