#delays {
  width: 100%;
  margin: 10px 0;
  flex-direction: column;
  select {
    margin: initial 10px;
  }
  button {
    margin: 0 10px;
    width: 100px;
  }
  table {
    width: calc(100% - 20px);
    margin: 10px 20px 0px 10px;
  }
}
