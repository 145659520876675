@import 'assets/styles/variables';

.tab {
  border: solid 1px $white_blue;
  padding: 5px 20px;
  &:not(last-child) {
    margin-right: 10px;
  }
  background: $white;
  color: $black;
  text-decoration: none;
  cursor: pointer;
  &.active,
  &:hover {
    color: $white;
    background: $primary;
    border: solid 1px transparent;
  }
}
