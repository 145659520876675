@import 'assets/styles/variables';

select {
  padding: 5px;
  display: block;
  width: 100%;
  background: $white;
  border: none;
  &.primary {
    background: $white_blue;
  }
}
