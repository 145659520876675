$blue: #03a9f4;
$white: #fff;
$white_blue: #f5fafd;
$black: #000;
$grey: #9e9e9e;
$more-grey: #636e72;
$menu_width: 70px;
$menu_expand_width: 140px;

$primary: $blue;
$background: $white_blue;
