@import 'assets/styles/variables';

.tag {
  background-color: $primary;
  display: inline-block;
  margin: 2px;
  padding: 5px 15px;
  border-radius: 30px;
  color: $white;
  .tag__content {
    display: flex;
    align-items: center;
  }
}
