@import 'assets/styles/variables';

nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: $primary;
  min-width: $menu_width;
  &.expand {
    min-width: $menu_expand_width;
    .items .item_container .item {
      justify-content: flex-start;
      padding: 20px 10px;
      text-decoration: none;
      &.active span,
      &:hover span {
        color: $primary;
      }
      span {
        display: flex;
        align-items: center;
        padding-left: 10px;
        color: $white;
      }
    }
  }
  .items {
    display: flex;
    flex-direction: column;
    width: 100%;
    .item_container {
      width: 100%;
      margin: 20px 0;
      text-decoration: none;
      position: relative;
      &.open .subItems {
        display: initial;
      }
      .item {
        padding: 20px 0;
        display: flex;
        align-content: center;
        justify-content: center;
        &.active,
        &:hover,
        &.open {
          background: $white;
        }
        &.active path,
        &:hover path,
        &.open path {
          fill: $primary;
        }
      }
      .subItems {
        background-color: $primary;
        color: $white;
        position: absolute;
        left: 100%;
        top: 0;
        min-width: 240px;
        z-index: 1000;
        display: none;
        .subItemTitle {
          padding: 20px;
          height: 36px;
          display: flex;
          align-items: center;
          background-color: $white;
          color: $primary;
        }
        a {
          display: block;
          text-decoration: none;
          color: $white;
          padding: 10px 20px;
          &:hover,
          &.active {
            background-color: $white;
            color: $primary;
          }
        }
      }
    }
  }
  img {
    padding: 10px;
  }
}
