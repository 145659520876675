@import 'assets/styles/variables';

.form_database {
  width: 80%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .title {
    width: 30%;
  }

  .select {
    width: 40%;
    margin-right: 20px;
  }

  .delete {
    text-align: center;
    margin: auto;

    &:hover {
      cursor: pointer;
    }
  }
}

.user_table {
  thead {
    margin-top: 50px;
  }
}
