@import 'assets/styles/variables';

#flights-schedules {
  padding: 30px;
  border-radius: 20px;
  background-color: $white;
  overflow-x: scroll;
  min-height: 70vh;
  .Flights-schedules__header {
    display: flex;
    div:first-child {
      margin-right: auto;
    }
  }
}

.flights {
  .modal__content {
    display: flex;
    flex-direction: column;
    padding: 10vh 0;
    min-height: 80vh;
    h2 {
      text-align: center;
    }
    label {
      font-family: 'Poppins-Bold' !important;
    }
    .form-part {
      margin: 20px 20%;
    }
    .part-one {
      flex-direction: row;
      display: flex;
      > div {
        flex: 1;
        padding: 20px 30px;
      }
      div > span {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        svg {
          margin: 5px 20px 5px 0;
        }
      }
      div:nth-child(1) {
        background: $blue;
        color: $white;
      }
      div:nth-child(2) {
        background: lighten($blue, 43%);
        h3 {
          color: $blue;
        }
      }
      div:nth-child(3) {
        background: lighten($blue, 48%);
        h3 {
          color: $blue;
        }
      }
    }
    .part-edit {
      border: 4px solid darken($white_blue, 10);
      .section {
        flex-direction: row;
        display: flex;
        &:nth-child(even) {
          background: $white;
        }
        .title {
          flex: 1;
          span {
            padding: 10px;
            display: flex;
            align-items: center;
            font-family: 'Poppins-SemiBold';
            color: $primary;
            svg {
              padding: 10px;
            }
          }
        }
        .inputs {
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          flex: 2;
          label {
            flex: 1;
            padding: 10px;
          }
        }
      }
    }
    .part-complement {
      margin-top: 20px;
      border: 3px solid #e7ecef;
      background: $white;
      > div {
        padding: 20px;
      }
      .top {
        display: flex;
        flex-direction: row;
        > div {
          flex: 1;
          margin: 0 10px;
        }
      }
    }
    input,
    select {
      margin: 10px 0;
    }
    label {
      font-family: 'Poppins-medium';
      &.calcul {
        display: flex;
        flex-direction: row;
        align-items: center;
        input {
          width: initial;
          margin: 5px;
        }
      }
      * {
        font-family: 'Poppins';
      }
    }
  }
}
