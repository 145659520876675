@import 'assets/styles/variables';

.QuickFlight {
  background-color: $white;
  margin: 30px 0px;
  padding: 20px 30px;
  border-radius: 20px;
  .QuickFlightHeader {
    display: flex;
    align-items: center;
    span {
      padding: 30px;
      .QuickFlightTitle {
        font-family: 'Poppins-Bold';
        font-size: 20px;
      }
      .QuickFlightType {
        color: $primary;
        font-size: 20px;
      }
    }
    button {
      margin-left: auto;
    }
  }
}
