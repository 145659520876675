@import 'assets/styles/variables';

.QuickFlightTable {
  width: 100%;
  margin-top: 20px;
  thead {
    background: #e7ecef;
    font-family: 'Poppins-Bold';
    tr th {
      div {
        display: flex;
        align-items: center;
        padding: 10px 5px;
      }
      svg {
        padding: 0 5px;
      }
    }
  }
  tbody {
    tr {
      &:nth-child(even) {
        background: $white_blue;
      }
      td {
        padding: 10px 10px;
      }
    }
  }
  .nodata {
    text-align: center;
    line-height: 100px;
    font-size: 30px;
    font-family: 'Poppins-SemiBold';
  }
}
