#SeatsStats {
  #SeatsUsed {
    flex: 2;
  }
  #SeatsGlobal {
    flex: 5;
  }
}
#SeatsStages {
  margin-top: 20px;
  height: initial !important;
}
