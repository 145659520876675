#FuelStats {
  .graphContainer {
    height: calc(100% - 80px);
  }
  #fuelUsed {
    flex: 5;
  }
  #fuelPlanned {
    flex: 2;
  }
}
