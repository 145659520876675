@import 'assets/styles/variables';

.table {
  padding-top: 20px;
  margin-top: 20px;
  .table__filter {
    display: flex;
    justify-content: flex-end;
    position: relative;
    .table__toggle-filter {
      cursor: pointer;
      background: $primary;
      color: $white;
      margin: 5px 0;
      padding: 10px;
      display: flex;
      align-items: center;
      &:hover {
        background: darken($primary, 5);
      }
      svg {
        padding: 3px;
      }
    }
    .options {
      z-index: 900 !important;
      max-height: 30vh;
      overflow: scroll;
      position: absolute;
      background-color: white;
      min-width: 150px;
      padding: 15px;
      border-radius: 20px;
      border: 1px solid rgb(211, 211, 211);
      box-shadow: 0 1px 4px rgba(0, 0, 0, 38);
      user-select: none;
      top: 100%;
      label {
        display: flex;
        height: 30px;
        align-items: center;
        cursor: pointer;
        &:nth-child(odd) {
          background-color: $white_blue;
        }
        span {
          padding: 4px;
        }
      }
    }
  }
  .table__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .table__footer-pagination {
      button {
        margin: 0 10px;
      }
    }
    select {
      background: darken($white, 5%);
    }
  }
  table {
    min-width: 100%;
    position: relative;
    border-collapse: collapse;

    thead {
      background: #e7ecef;
      position: sticky;
      top: -20px; /* Don't forget this, required for the stickiness */
      box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
      color: $black;
      tr th {
        padding: 20px;
        vertical-align: middle;
        &:first-child {
          text-align: center;
          padding: 15px 10px;
        }
      }
    }
    tbody {
      tr {
        background: $white;
        &:nth-child(even) {
          background: $white_blue;
        }
        &.past-flight {
          background: $grey;
          &:nth-child(even) {
            background: lighten($grey, 3%);
          }
        }
        &.cancelled-flight {
          background: $more-grey;
          &:nth-child(even) {
            background: lighten($more-grey, 5%);
          }
        }
        &.in-flight {
          background: $blue;
          &:nth-child(even) {
            background: lighten($blue, 5%);
          }
        }
        &.updated-flight {
          background: #f74b4b;
          &:nth-child(even) {
            background: lighten(#f74b4b, 5%);
          }
        }
        td {
          padding: 0 20px;
          white-space: nowrap;
          &.icon {
            vertical-align: middle;
            svg {
              border: 1px solid lighten($black, 90%);
              padding: 5px;
              border-radius: 10%;
              cursor: pointer;
              &:hover {
                background: $primary;
                border: 1px solid $primary;
                fill: $white;
              }
            }
          }
        }
        td:first-child {
          text-align: center;
          padding: 15px 10px;
        }
      }
    }
  }
}

.italic {
  font-style: italic;
}
