@import 'assets/styles/variables';

header {
  background: $white;
  line-height: 70px;
  height: 70px;
  justify-content: space-between;
  display: flex;
  padding: 0 20px;
  color: $primary;
  div {
    display: flex;
    align-items: center;
    span {
      padding: 0 10px;
    }
    svg {
      cursor: pointer;
    }
  }
  .accountInformations {
    z-index: 50;
    background: $white_blue;
    position: absolute;
    border: 1px solid black;
    border-top: none;
    border-right: none;
    padding: 20px;
    margin: 0;
    min-width: 200px;
    top: 70px;
    right: 0;
    .accountInformations__content {
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      p {
        margin: 5px 10px;
        line-height: initial;
        span {
          font-family: 'Poppins-SemiBold';
          padding: 0;
        }
      }
      button {
        align-self: center;
      }
    }
  }
}
