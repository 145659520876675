@import 'assets/styles/variables';

#mission {
  .content {
    width: 60%;
    margin: auto;
    label {
      font-family: 'Poppins-Bold';
      input {
        margin: 10px 0;
        font-family: 'Poppins';
        background: $white_blue;
      }
    }
    button {
      display: flex;
      margin: 5px auto;
    }
  }
}
