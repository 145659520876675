@import 'assets/styles/variables';

.Login {
  display: flex;
  flex-direction: row;
  height: 100vh;
  > div {
    &.form {
      display: flex;
      flex: 2;
      flex-direction: column;
      .logoContainer {
        text-align: center;
        margin-top: 150px;
        img {
          max-width: 200px;
        }
      }
      .container {
        margin: 0 auto;
        width: 60%;
        margin-top: 100px;
        h1 {
          text-align: center;
          font-size: 20px;
          font-family: 'Poppins-Bold';
          margin: 20px 0;
        }
        .title {
          margin: 80px 0;
        }
        form label,
        form span {
          display: block;
          margin: 15px 0;
        }
        form span {
          display: flex;
        }
        span {
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      a {
        color: $primary;
        text-decoration: none;
        font-family: 'Poppins-Medium';
      }
    }
    &.img {
      display: flex;
      height: 100vh;
      flex: 3;
      justify-content: flex-end;
      background-image: url('../../../assets/images/loginImg.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom center;
    }
  }
}
