@import 'assets/styles/variables';

#stats {
  padding: 30px;
  border-radius: 20px;
  background-color: $white;
  overflow-x: scroll;
  min-height: 70vh;
  .statsContainer {
    flex-direction: row;
    display: flex;
    margin-top: 30px;
    box-sizing: border-box;
    gap: 10px;
    .graphContainer {
      height: calc(100%);
    }
  }
  .statsSection {
    border: 3px solid $white_blue;
    border-radius: 10px;
    padding: 40px;
    height: 55vh;
    h3 {
      margin: 0;
      font-family: 'Poppins-Bold', serif;
    }
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  .highlight_value {
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    align-items: center;
    div {
      flex-direction: column;
      display: flex;
      margin: 20px 0;
      span {
        font-family: 'Poppins-SemiBold', serif;
        &:first-child {
          font-size: 25px;
        }
        &:last-child {
          color: $primary;
        }
      }
      label {
        display: flex;
        align-items: center;
        input {
          margin: 0 5px;
        }
      }
    }
  }
}
