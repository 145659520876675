.menuIcon {
  svg {
    transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .active {
    svg {
      transform: rotate(90deg);
    }
    path {
      &:nth-child(1) {
        transform: translateX(9px) translateY(1px) rotate(45deg);
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        transform: translateX(9px) translateY(1px) rotate(-45deg);
      }
      &:nth-child(4) {
        display: none;
      }
      &:nth-child(5) {
        transform: translateX(9px) translateY(1px) rotate(-45deg);
      }
      &:nth-child(6) {
        transform: translateX(9px) translateY(1px) rotate(45deg);
      }
    }
  }
  path {
    transition:
      transform 500ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
    &:nth-child(1) {
      transform-origin: 36% 40%;
    }
    &:nth-child(2) {
      stroke-dasharray: 29 299;
    }
    &:nth-child(3) {
      transform-origin: 35% 63%;
    }
    &:nth-child(4) {
      stroke-dasharray: 29 299;
    }
    &:nth-child(5) {
      transform-origin: 61% 52%;
    }
    &:nth-child(6) {
      transform-origin: 62% 52%;
    }
  }
}
