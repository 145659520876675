@import 'assets/styles/variables';

.page {
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  display: flex;
  > div {
    display: inline-block;
    align-items: flex-end;
    height: 100%;
    width: calc(100% - 70px);
    background-color: $white_blue;
    > div.content {
      padding: 20px 40px;
      height: calc(100vh - 70px - 40px);
      overflow-y: scroll;
    }
  }
  &.navExpand {
    > div {
      width: calc(100% - 140px);
    }
  }
  h1 {
    font-family: 'Poppins-Bold';
    margin: 0;
    font-size: 20px;
  }
  h2 {
    font-family: 'Poppins-Medium';
    margin: 0;
    font-size: 20px;
    color: $primary;
    margin-bottom: 20px;
  }
}
