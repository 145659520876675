.dateStamp {
  display: inline-block;
  text-align: center;
  border: 1px solid #e7ecef;
  padding: 15px 25px;
  .day {
    font-size: 16px;
    letter-spacing: 1.6px;
  }
  .date {
    font-family: 'Poppins-Bold';
    font-size: 40px;
  }
  .month {
    font-family: 'Poppins-Medium';
    font-size: 20px;
  }
}
