@import 'assets/styles/variables';

input.customInput {
  padding: 9px 9px;
  outline-width: 0;
  width: calc(100% - 18px);
  border: none;
  &::-webkit-input-placeholder,
  &::-moz-placeholder {
    color: grey;
  }
  &.primary {
    background: $white_blue;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
