@import 'assets/styles/variables';

.confirmDelete {
  background: #ffffffdd;
  position: absolute;
  display: flex;
  align-items: center;
  width: 100vw;
  min-height: 100%;
  top: 0;
  left: 0;
  z-index: 201;
  .popup__content {
    position: relative;
    background: $white;
    border: 1px solid $primary;
    padding: 3%;
    width: 60%;
    margin: auto;
    h2 {
      color: $black;
      font-size: 34px;
      font-family: 'Poppins-SemiBold';
      text-align: center;
    }
    h3 {
      text-align: center;
    }
    p {
      margin: 0;
    }
    .toggle_list {
      text-decoration: underline;
      font-family: 'Poppins-SemiBold';
      cursor: pointer;
    }
    .list {
      max-height: 140px;
      overflow: scroll;
      p {
        padding: 10px 0 10px 15px;
        &:nth-child(even) {
          background: $white_blue;
        }
      }
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin: 20px 0;
      p {
        cursor: pointer;
        font-family: 'Poppins-SemiBold';
        &:hover {
          text-decoration: underline;
        }
      }
      button {
        margin-right: 10px;
      }
    }
  }
  .close {
    position: absolute;
    display: inline-block;
    top: 20px;
    right: 20px;
  }
}
